.about-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    
    @media (--from-normal-screen-w) {
        flex-direction: row;
        align-items: stretch;
        justify-content: center;
    }

    & > * {
        flex: 1;
    }

    .thumbnail {
        background-color: white;
        padding: rem(50);

        &-image {
            width: 100%;
            height: auto;
            margin-bottom: rem(40);
        }
    }

    .content {
        background-color: rgb(53, 47, 45);
        color: $color-light;
        padding: rem(50);
        width: 100%;

        .icon {
            display: block;
            margin-bottom: rem(20);
            color: $color-primary;
        }

        .content-inner p:last-child {
            margin-bottom: 0;
        }
    }
}
